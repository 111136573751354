export class LineItemOptionModel {
  public value: string;
  public title: string;
  constructor({value, title}: {value: string; title: string}) {
    this.value = value;
    this.title = title;
  }

  public static convertToList(fields: Record<string, string>): LineItemOptionModel[] {
    return Object.keys(fields)
      .filter((key) => !!fields[key]?.trim())
      .map((key) => {
        return new LineItemOptionModel({
          value: fields[key],
          title: key,
        });
      });
  }
}
